import dayjs from "dayjs";

export const debounce = (fn: any, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const hour12ToHour24 = (time: string) => {
  const d = new Date("1/1/2013 " + time);
  return dayjs(d).format("HHmm");
};

export const retryUntilSomethingIsRight = async <T>(cb: () => Promise<T | undefined>, time: number) => {
  return new Promise<T>((res) => {
    const id = setInterval(async () => {
      const r = await cb();

      if (r) {
        clearInterval(id);
        res(r);
      }
    }, time);
  });
};

export const giveMinuteInSeconds = (minute: number) => minute * 1000 * 60;

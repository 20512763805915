import React, { useRef } from "react";
import { Box, LayoutProps, PageMeta as BluPageMeta, PageMetaProps } from "blucharge-react-core";

import { Snackbar } from "components/snackbar";

export const PageMeta: React.FC<PageMetaProps> = ({ children, ...props }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box
      id="page-meta"
      ref={(node) => {
        wrapperRef.current = node;
      }}
      width="100%"
      style={{ transition: "500ms all", overflowX: "hidden" }}
    >
      <BluPageMeta<LayoutProps> withLayout {...props}>
        {children}
        <Snackbar />
      </BluPageMeta>
    </Box>
  );
};

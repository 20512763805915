import { createGlobalStyle } from "styled-components";

const styled = { createGlobalStyle };

export const GlobalStyles = styled.createGlobalStyle`
  #root {
    height: 100%;
    width: 100%;
  }

  html,
  body {
    height: 100vh;
    width: 100%;
  }

  * {
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    padding: 0;
    margin: 0;

    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      border-radius: 10px;
      background-color: rgba(147, 156, 171, 1) !important;

      background-clip: content-box;
      -webkit-background-clip: content-box;

      transition: all 1s;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(147, 156, 171, 1) !important;
      background-clip: border-box;
      -webkit-background-clip: border-box;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: transparent;
    }
  }

  @keyframes downToUp {
    0% {
      transform: translateY(200px);
      opacity: 0.5;
    }

    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes upToDown {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }

    100% {
      transform: translateY(200px);
      opacity: 0.5;
    }
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(1000px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes fadeOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

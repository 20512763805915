import Spinner from "@atlaskit/spinner";
import { Box } from "blucharge-react-core";

export const FullPageLoader = () => {
  return (
    <Box display="flex" width="100vw" height="100vh" justifyContent="center" alignItems="center">
      <Spinner size="large" />
    </Box>
  );
};

// @ts-nocheck
import { CSSProperties, forwardRef, useMemo } from "react";
import styled from "styled-components";

type Spacing = number | "auto";

const cssKeys: {
  [_k in keyof CSSProperties | "marginX" | "marginY" | "paddingX" | "paddingY"]?: boolean;
} = {
  margin: true,
  marginTop: true,
  marginBottom: true,
  marginLeft: true,
  marginRight: true,
  padding: true,
  paddingTop: true,
  paddingBottom: true,
  paddingLeft: true,
  paddingRight: true,
  cursor: true,
  display: true,
  justifyContent: true,
  alignItems: true,
  flexDirection: true,
  gap: true,
  width: true,
  maxWidth: true,
  height: true,
  maxHeight: true,
  position: true,
  top: true,
  right: true,
  left: true,
  bottom: true,
  backgroundColor: true,
  background: true,
  zIndex: true,
  flex: true,
  border: true,
  borderColor: true,
  borderRadius: true,
  borderWidth: true,
  borderStyle: true,
  marginX: true,
  marginY: true,
  paddingY: true,
  paddingX: true,
};

type CSSKeys = keyof typeof cssKeys;

export type BoxPropTypes = Partial<
  {
    [k in CSSKeys]: k extends keyof CSSProperties ? CSSProperties[k] : Spacing;
  } & JSX.IntrinsicElements["div"]
>;

export type BoxPropTypesDollared = Partial<{ [k in `$${CSSKeys}`]: any } & Omit<JSX.IntrinsicElements["div"], "ref">>;

const orientationSpacing = (props: BoxPropTypesDollared, key: "padding" | "margin") => {
  const margin: any = [0, 0];

  const horizontalKey = `$${key}X`;
  const verticalKey = `$${key}Y`;

  if (props[`$${key}`]) {
    return spacingHelper(props, key);
  }

  if (props[verticalKey]) {
    margin[0] = props[verticalKey];
  }

  if (props[horizontalKey]) {
    margin[1] = props[horizontalKey];
  }

  return margin.map((e) => (e ? `${e}px` : "0px")).join(" ");
};

const spacingHelper = (props: BoxPropTypesDollared, k: keyof BoxPropTypes) => {
  const key = `$${k}`;

  return typeof props[key] === "string" ? props[key] : props[key] === undefined ? undefined : props[key] + "px";
};

const stringHelper = (props: BoxPropTypesDollared, key: keyof BoxPropTypes) => props[`$${key}`] ?? undefined;

const StyledBox = styled.div<BoxPropTypesDollared>`
  margin: ${(props) => orientationSpacing(props, "margin")};

  margin-top: ${(props) => spacingHelper(props, "marginTop")};
  margin-bottom: ${(props) => spacingHelper(props, "marginBottom")};
  margin-left: ${(props) => spacingHelper(props, "marginLeft")};
  margin-right: ${(props) => spacingHelper(props, "marginRight")};

  padding: ${(props) => orientationSpacing(props, "padding")};

  padding-top: ${(props) => spacingHelper(props, "paddingTop")};
  padding-bottom: ${(props) => spacingHelper(props, "paddingBottom")};
  padding-left: ${(props) => spacingHelper(props, "paddingLeft")};
  padding-right: ${(props) => spacingHelper(props, "paddingRight")};

  cursor: ${(props) => stringHelper(props, "cursor")};

  display: ${(props) => stringHelper(props, "display")};
  justify-content: ${(props) => stringHelper(props, "justifyContent")};
  align-items: ${(props) => stringHelper(props, "alignItems")};
  flex-direction: ${(props) => stringHelper(props, "flexDirection")};

  gap: ${(props) => spacingHelper(props, "gap")};

  width: ${(props) => spacingHelper(props, "width")};
  max-width: ${(props) => spacingHelper(props, "maxWidth")};

  height: ${(props) => spacingHelper(props, "height")};
  max-height: ${(props) => spacingHelper(props, "maxHeight")};

  position: ${(props) => stringHelper(props, "position")};
  top: ${(props) => spacingHelper(props, "top")};
  right: ${(props) => spacingHelper(props, "right")};
  left: ${(props) => spacingHelper(props, "left")};
  bottom: ${(props) => spacingHelper(props, "bottom")};

  background-color: ${(props) => stringHelper(props, "backgroundColor")};
  background: ${(props) => stringHelper(props, "background")};

  z-index: ${(props) => stringHelper(props, "zIndex")};
  flex: ${(props) => stringHelper(props, "flex")};

  border: ${(props) => stringHelper(props, "border")};
  border-color: ${(props) => stringHelper(props, "borderColor")};
  border-radius: ${(props) => spacingHelper(props, "borderRadius")};
  border-width: ${(props) => spacingHelper(props, "borderWidth")};
  border-style: ${(props) => stringHelper(props, "borderStyle")};
`;

export const Box = forwardRef<HTMLDivElement, BoxPropTypes>(({ children, ...props }, ref) => {
  const dollarProps = useMemo(
    () =>
      Object.keys(props).reduce((acc, key) => {
        if (cssKeys[key] === true) {
          acc[`$${key}`] = props[key];
        } else {
          acc[key] = props[key];
        }

        return acc;
      }, {} as BoxPropTypesDollared),
    [props]
  );

  return (
    <StyledBox ref={ref} {...dollarProps}>
      {children}
    </StyledBox>
  );
});

Box.displayName = "Box";

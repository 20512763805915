import { axiosClient } from "network/core";
import { ACCESS } from "network/endpoints";

export const doLogin = async ({ username, password }: LoginPostBody) => {
  const res = await axiosClient.post<LoginPostBody, LoginResponse>({
    url: ACCESS.VERIFY_PASSWORD,
    data: {
      username,
      password,
    },
  });

  return res;
};

export const doCheckToken = async () => {
  const res = await axiosClient.get<UserData>({
    url: ACCESS.CHECK_TOKEN,
  });

  return res;
};

export const doLogout = async () => {
  const res = await axiosClient.post({
    url: ACCESS.LOGOUT,
    data: undefined,
  });

  return res;
};

import { lazy, Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import toast from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Box } from "blucharge-react-core";

import ErrorBoundary from "core/ErrorBoundary";
import { Page404 } from "core/Page404";

import { FullPageLoader } from "components/full-page-loader";
import { ProtectedRoutes } from "components/protected-routes";
import { Snackbar } from "components/snackbar";

import { ROUTES } from "routes";

import { GlobalStyles } from "styles";

const Vehicle = lazy(() => import("pages/vehicle"));
const Driver = lazy(() => import("pages/driver"));
const Facilities = lazy(() => import("pages/stations"));
const Login = lazy(() => import("pages/login"));
const Profile = lazy(() => import("pages/profile"));
const Visit = lazy(() => import("pages/visits"));
const Payments = lazy(() => import("pages/payments"));
const Sidebar = lazy(() => import("components/sidebar"));
export const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: twentyFourHoursInMs,
      refetchOnWindowFocus: false,
    },
  },
});

// if (import.meta.env.MODE === "production") {
//   Sentry.init({
//     dsn: "https://00b95f5d6565bc0e875782b7f612ba8e@o4506024583692288.ingest.sentry.io/4506024684355584",
//     integrations: [
//       new Sentry.BrowserTracing({
//         routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//           useEffect,
//           useLocation,
//           useNavigationType,
//           createRoutesFromChildren,
//           matchRoutes
//         ),
//       }),
//     ],
//     tracesSampleRate: 1.0,
//   });
// }

const Suspensed: React.FC<{ component: React.FC }> = ({ component: Component }) => (
  <ErrorBoundary>
    <Suspense fallback={<FullPageLoader />}>
      <Component />
    </Suspense>
  </ErrorBoundary>
);

const App = () => {
  useEffect(() => {
    localStorage.setItem("modal-z-index", "12");
    window.addEventListener("online", () => {
      toast.success(
        `"Hooray! Your digital toaster is now connected to the internet, ready to toast up a fresh online experience for you. Enjoy the crumbs of connectivity! 📡🌐🍞"`,
        { id: "network", duration: 3000 }
      );
    });

    window.addEventListener("offline", () => {
      toast.error(
        `"Oops! It seems our digital toaster can't find a Wi-Fi connection to butter up your web experience. Please check your internet connection and try again. 📡🍞"`,
        {
          id: "network",
          duration: Infinity,
        }
      );
    });
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GlobalStyles />
        <GoogleOAuthProvider clientId="393352348407-cctavkfvbphf4cuc3957n3nnv7r94uun.apps.googleusercontent.com">
          <Box display="flex" height="100%" style={{ overflow: "auto" }}>
            <Suspense fallback={<></>}>
              <Sidebar />
            </Suspense>

            <Routes>
              <Route element={<Suspensed component={ProtectedRoutes} />}>
                <Route path={ROUTES.login} Component={Login} />
                <Route path={ROUTES.vehicles} Component={Vehicle} />
                <Route path={ROUTES.visits} Component={Visit} />
                <Route path={ROUTES.profile} Component={Profile} />
                <Route path={ROUTES.drivers} Component={Driver} />
                <Route path={ROUTES.stations} Component={Facilities} />
                <Route path={ROUTES.payments} Component={Payments} />
              </Route>
              <Route path="*" Component={Page404} />
            </Routes>
          </Box>
        </GoogleOAuthProvider>
        <ReactQueryDevtools position="bottom-left" initialIsOpen={true} />
        <Snackbar
          toasterProps={{
            toastOptions: {
              id: "network",
            },
          }}
        />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);

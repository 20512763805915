import { MutationFunction, useMutation, UseMutationOptions, useQueries, useQuery } from "@tanstack/react-query";

export const useFetch = <T>({ key, ...options }: UseFetchParams<T>) => {
  return useQuery({
    queryKey: key,
    ...options,
  });
};

export const useFetches = ({ queries }: UseFetchesParams) => {
  return useQueries({
    queries,
  });
};

export const useMutate = <T, X>({
  fn,
  ...rest
}: { fn: MutationFunction<BaseResponse<T>, X> } & UseMutationOptions<BaseResponse<T>, Error, X>) => {
  const res = useMutation<BaseResponse<T>, Error, X>(fn, {
    ...rest,
  });

  return res;
};

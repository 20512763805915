import React, {
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import styled from "styled-components";

import { Box } from "../box";
import { Text } from "../text";

const ModalContainer = styled(Box)<{
  $width?: number;
  $show: boolean;
  $zIndex: number;
}>`
  position: fixed;
  background-color: #fff;
  height: 100%;
  right: ${(props) =>
    props.$show ? `0px` : props.width ? `-${props.width}px` : "-400px"};
  width: ${(props) => (props.width ? `${props.width}px` : "400px")};
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  top: 0;
  transition: all 0.3s ease-out;
  z-index: ${(p) => p.$zIndex};
`;

const Header = styled(Box)`
  border-bottom: 2px solid #eaeaea;
  padding: 16px 20px;
`;

interface Props extends PropsWithChildren {
  width?: number;
  hideHeader?: boolean;
  hideClose?: boolean;
  headerTitle?: string;
  showBack?: boolean;
  onBackPress?: () => void;
  onClosePress?: () => void;
  defaultVisibleState?: boolean;
}

export interface ModalRightRef {
  open: () => void;
  close: () => void;
}

// eslint-disable-next-line react/display-name
const M: React.ForwardRefRenderFunction<ModalRightRef, Props> = (
  {
    width,
    hideHeader,
    headerTitle,
    showBack,
    hideClose,
    onBackPress,
    onClosePress,
    children,
    defaultVisibleState = true,
  },
  ref
) => {
  const [modalVisible, setModalVisibility] = useState(defaultVisibleState);

  const [zIndex, setZindex] = useState(Date.now());

  useImperativeHandle(ref, () => ({
    open: () => {
      setModalVisibility(true);
    },
    close: () => {
      setModalVisibility(false);
    },
  }));

  useEffect(() => {
    if (modalVisible) {
      const index = localStorage.getItem("modal-z-index") || 12;
      setZindex(+index + 1);

      localStorage.setItem("modal-z-index", +index + 1 + "");
    }
  }, [modalVisible]);

  const onClose = () => {
    if (onClosePress) {
      onClosePress();
    }
    setModalVisibility(false);
  };

  return (
    <ModalContainer $width={width} $show={modalVisible} $zIndex={zIndex}>
      {!hideHeader && (
        <Header
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {!!showBack && (
            <Box onClick={onBackPress}>
              <ArrowLeftIcon label="modal-back" />
            </Box>
          )}
          <Box>
            <Text>{headerTitle}</Text>
          </Box>
          {!hideClose && (
            <Box onClick={onClose}>
              <CrossIcon label="modal-close" />
            </Box>
          )}
        </Header>
      )}
      {children}
    </ModalContainer>
  );
};

export const ModalRight = React.memo(React.forwardRef(M));

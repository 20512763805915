import React, { PropsWithChildren } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { FullPageLoader } from "components/full-page-loader";

import { useCheckToken, useProfileData } from "network/queries/auth";

import { ROUTES } from "routes";

export const ProtectedRoutes: React.FC<PropsWithChildren> = () => {
  const { data, isFetching } = useCheckToken();

  useProfileData({
    isLoggedIn: data?.isSuccessful || false,
  });

  const location = useLocation();

  const pathname = location.pathname;

  if (isFetching) {
    return <FullPageLoader />;
  }

  if (data?.response) {
    if (pathname === ROUTES.login) return <Navigate to={ROUTES.stations} replace />;
    else return <Outlet />;
  } else {
    if (pathname === ROUTES.login) return <Outlet />;
    else {
      return <Navigate to={ROUTES.login} replace />;
    }
  }
};

import { Component, ErrorInfo, ReactNode } from "react";
import { Box, Button } from "blucharge-react-core";

import { PageMeta } from "components/page-meta";
import { Text } from "components/text";

import errorTechnical from "assets/images/error_technical.png";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageMeta withLayout={false} title="Page not found">
          <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
            <Box display="flex" flexDirection="column" gap={48} alignItems="center">
              <Box display="flex" flexDirection="column" gap={36} alignItems="center">
                <img width={124} height={124} src={errorTechnical} alt="not_found" />
                <Box display="flex" flexDirection="column" gap={16} alignItems="center">
                  <Text fontSize={48} color="#CC1A1A" fontWeight={600}>
                    Technical Error
                  </Text>
                  <Text fontSize={18} color="rgba(15, 15, 15, 0.60)" fontWeight={400}>
                    Whoops! There has been a technical error. Please wait for some time while we resolve the issue.
                  </Text>
                </Box>
              </Box>

              <Box display="flex" gap={20}>
                <Button
                  onClick={() => window.location.reload()}
                  style={{
                    width: 170,
                    height: 60,
                    display: "flex",
                    alignItems: "center",
                    fontSize: 16,
                    border: "1px solid #C7C7C7",
                    borderRadius: 3,
                    color: "#898989",
                    fontWeight: 400,
                    background: "#fff",
                  }}
                >
                  Refresh
                </Button>
              </Box>
            </Box>
          </Box>
        </PageMeta>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

/* eslint-disable react/display-name */
import { forwardRef, PropsWithChildren } from "react";
import { Text as T, TextProps } from "blucharge-react-core";

export const Text = forwardRef<HTMLParagraphElement, PropsWithChildren<TextProps<"p">>>((props, ref) => {
  return (
    <T ref={ref} {...props}>
      {props.children}
    </T>
  );
});

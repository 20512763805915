import React, { useCallback, useEffect, useState } from "react";
import toast, { LoaderIcon, ToastBar, Toaster, ToasterProps, ToastType } from "react-hot-toast";
import { Box } from "blucharge-react-core";
import styled from "styled-components";

import { CrossIcon } from "assets/icon/cross";

const Wrapper = styled(Box)`
  & > div {
    justify-content: unset;
    margin: 0;
    padding: 12px 20px;
  }
`;

export type SnackbarProps = { toasterProps?: ToasterProps };

export const Snackbar: React.FC<SnackbarProps> = ({ toasterProps = {} }) => {
  const [sidebarWidth, setSidebarWidth] = useState(+(localStorage.getItem("sidebar-width") || 0));

  const getColor = useCallback((type: ToastType) => {
    switch (type) {
      case "success":
        return "#5EBB72";
      case "loading":
        return "#5EBB72";
      case "error":
        return "#ed4337";
      default:
        break;
    }
  }, []);

  const widthCalculator = () => {
    const localWidth = localStorage.get("sidebar-width");
    if (localWidth === null) return;

    setSidebarWidth(+localWidth);
  };

  useEffect(() => {
    window.addEventListener("storage", widthCalculator);

    return () => {
      window.removeEventListener("storage", widthCalculator);
    };
  }, []);

  return (
    <Toaster
      position="bottom-left"
      containerStyle={{
        right: 0,
        marginTop: "auto",
        bottom: 0,
        left: 0,
        zIndex: 1000,
      }}
      {...toasterProps}
    >
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            ...t.style,
            bottom: 0,
            width: "100%",
            maxWidth: "unset",
            background: "none",
            padding: 0,
            paddingLeft: sidebarWidth,
            transition: "500ms all",
            boxShadow: "unset",
            animation: t.visible ? "downToUp 100ms ease forwards" : "upToDown 100ms ease forwards",
          }}
        >
          {({ message }) => (
            <Wrapper
              display="flex"
              alignItems="center"
              width="100%"
              background={getColor(t.type || "success")}
              style={{
                color: "white",
              }}
            >
              {message}
              {t.type !== "loading" && Number.isFinite(t.duration) ? (
                <Box
                  marginLeft="auto"
                  display="flex"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => {
                    toast.dismiss(t.id);
                  }}
                >
                  <CrossIcon color="white" />
                </Box>
              ) : (
                <Box marginLeft="auto" display="flex" alignItems="center">
                  <LoaderIcon style={{ width: 24, height: 24 }} />
                </Box>
              )}
            </Wrapper>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

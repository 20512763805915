import React from "react";
import {
  DatePicker as AtlasDatepicker,
  DatePickerProps as D,
} from "@atlaskit/datetime-picker";
import styled from "styled-components";

import { Box } from "../box";

const Wrapper = styled(Box)`
  & > div {
    div {
      font-size: 12px;
    }
  }
`;

export type DatePickerProps = D;
export const Datepicker: React.FC<DatePickerProps> = ({ ...rest }) => {
  return (
    <Wrapper>
      <AtlasDatepicker {...rest} />
    </Wrapper>
  );
};

export const BackIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.207 5.7928C12.2999 5.88559 12.3737 5.99579 12.424 6.11711C12.4743 6.23842 12.5002 6.36846 12.5002 6.4998C12.5002 6.63113 12.4743 6.76118 12.424 6.88249C12.3737 7.00381 12.2999 7.11401 12.207 7.2068L8.414 10.9998H18C18.2652 10.9998 18.5196 11.1052 18.7071 11.2927C18.8946 11.4802 19 11.7346 19 11.9998C19 12.265 18.8946 12.5194 18.7071 12.7069C18.5196 12.8944 18.2652 12.9998 18 12.9998H8.414L12.207 16.7928C12.2999 16.8856 12.3737 16.9958 12.424 17.1171C12.4743 17.2384 12.5002 17.3685 12.5002 17.4998C12.5002 17.6311 12.4743 17.7612 12.424 17.8825C12.3737 18.0038 12.2999 18.114 12.207 18.2068C11.817 18.5968 11.183 18.5968 10.792 18.2068L5.293 12.7068C5.1119 12.5264 5.00701 12.2833 5 12.0278V11.9708C5.00716 11.7156 5.11205 11.4729 5.293 11.2928L10.792 5.7928C10.8849 5.69982 10.9952 5.62606 11.1166 5.57574C11.238 5.52541 11.3681 5.49951 11.4995 5.49951C11.6309 5.49951 11.761 5.52541 11.8824 5.57574C12.0038 5.62606 12.1141 5.69982 12.207 5.7928Z"
        fill="#44546F"
      />
    </svg>
  );
};

export const ROUTES = {
  login: "/",
  vehicles: "/vehicles",
  visits: "/charging-transactions",
  drivers: "/drivers",
  profile: "/profile",
  stations: "/stations",
  payments: "/payments",
} as const;

export type RouteName = keyof typeof ROUTES;

export type RouteValues = typeof ROUTES extends Record<string, infer D> ? D : never;

import React, { forwardRef, PropsWithChildren } from "react";
import { LoadingButton, LoadingButtonProps } from "@atlaskit/button";

export type ButtonProps = Omit<
  LoadingButtonProps,
  "appearance" | "iconAfter" | "iconBefore"
> & {
  variant?: LoadingButtonProps["appearance"];
  leftAdornment?: LoadingButtonProps["iconBefore"];
  rightAdornment?: LoadingButtonProps["iconAfter"];
};

export const Button: React.FC<PropsWithChildren<ButtonProps>> = forwardRef<
  HTMLElement,
  PropsWithChildren<ButtonProps>
>(({ children, variant, rightAdornment, leftAdornment, ...props }, ref) => {
  return (
    <LoadingButton
      ref={ref}
      appearance={variant}
      iconAfter={rightAdornment}
      iconBefore={leftAdornment}
      {...props}
    >
      {children}
    </LoadingButton>
  );
});

Button.displayName = "Button";

/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  forwardRef,
  PropsWithChildren,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { SVGProps } from "react";
import Tooltip, { TooltipPrimitive, TooltipPrimitiveProps } from "@atlaskit/tooltip";
import styled from "styled-components";

import { Box } from "../";
import { Text } from "../text";
import { SidebarActionIcon } from "./assets/sidebar-action";

export type NavbarIconProps = SVGProps<SVGSVGElement> & { active: boolean };

const COLORS = {
  sidebarBackground: "#F9F9F9",
  blueColor: "#1B55D1",
  dividerGray: "#DEDEDE",
  lightBlue: "#ECEFF7",
  sidebarText: "#42526E",
  badgeColor: "#CCE0FF",
  dividerGray2: "#DCDFE4",
};

export type NavbarButtonTypes<T = any> = {
  label: T;
  icon: React.FC;
  activeIcon?: React.FC;
  activeOnClick?: boolean;
  badge?: string;
  tooltipContent?: any;
  onClick: (d: NavbarButtonTypes<T>) => void;
};

type NavbarProps<T> = {
  primaryButtons: NavbarButtonTypes[];
  secondaryButtons: NavbarButtonTypes[];
  logo: React.ReactNode;
  onButtonClick?: (d: NavbarButtonTypes<T>) => void;
  onLogoClick?: () => void;
  defaultSelected?: string;
  onExpand?: (d: boolean | undefined, width: number) => void;
  defaultExpanded?: boolean | undefined;
};

const SidebarAction = styled(Box)<{ expanded: boolean | undefined }>`
  position: absolute;
  top: 50%;
  right: 0;

  svg {
    transform: translate(0%, 50%) ${(props) => (!props.expanded ? "rotateZ(-180deg)" : "")};
  }

  transition: 100ms all;

  opacity: 0;
`;

const Wrapper = styled(Box)<{
  expanded: boolean | undefined;
}>`
  transition: 100ms all;
  width: 56px;

  animation: ${(props) => (typeof props.expanded === "undefined" ? "" : props.expanded ? "expand" : "collapse")}
    forwards 500ms;

  @keyframes expand {
    from {
      width: 56px;
    }
    to {
      width: 150px;
    }
  }

  @keyframes collapse {
    from {
      width: 150px;
    }
    to {
      width: 56px;
    }
  }

  &:hover ${SidebarAction} {
    opacity: 1;
  }
`;

const NavbarIconWrapper = styled(Box)<{ expanded: boolean | undefined }>`
  display: flex;
  position: relative;
  flex-direction: row;
  /* justify-content: ${(props) => (props.expanded ? "unset" : "center")}; */
  align-items: center;
  /* gap: 4px; */

  padding: 8px;
  height: 48px;
  width: 100%;

  cursor: pointer;

  & > div:first-child {
    top: 8px;
    transform: ${(props) => (!props.expanded ? "translateX(3.5px)" : "translateX(0)")};

    transition: 1s all ease-in-out;

    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const DummyBoxWhichIsMoving = styled(Box)`
  position: absolute;

  height: 48px;
  width: 100%;

  cursor: pointer;
  background: #eceff7;
  transition: 100ms all;
`;

// eslint-disable-next-line react/display-name
const TooltipC = forwardRef<any, PropsWithChildren<TooltipPrimitiveProps>>(({ children, ...rest }, ref) => {
  return (
    <ToolWrapper {...rest} ref={ref}>
      <Box borderRadius={3}>{children}</Box>
      <Box
        position="absolute"
        top="50%"
        left={0}
        height={10}
        width={10}
        background="white"
        style={{
          transform: "translate(-50%, -50%) rotateZ(45deg)",
          borderRadius: "0px 0px 0px 3px",
        }}
      />
    </ToolWrapper>
  );
});

const ToolWrapper = styled(TooltipPrimitive)`
  display: flex;
  align-items: center;

  border-radius: 3px;
  background-color: #fff;
  filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.18));
  padding: 6px;

  color: #434343;
  font-weight: 700;
  font-size: 12px;

  box-sizing: content-box;

  width: fit-content;
`;

const NavbarIcon: React.FC<
  {
    active?: boolean;
    expanded?: boolean | undefined;
  } & NavbarButtonTypes
> = (props) => {
  const {
    active = false,
    icon: Icon,
    label,
    onClick,
    expanded = false,
    activeOnClick = true,
    tooltipContent,
    activeIcon: ActiveIcon,
  } = props;
  return (
    <Tooltip position="right" component={TooltipC} content={tooltipContent}>
      {({ onClick: c, ...tooltipProps }) => (
        <a
          onClick={() => onClick(props)}
          href="#"
          style={{
            textDecoration: "none",
            display: "block",
          }}
        >
          <NavbarIconWrapper
            expanded={expanded}
            // backgroundColor={activeOnClick && active ? "#ECEFF7" : "transparent"}
            {...tooltipProps}
          >
            <Box
              position="absolute"
              left={label.length ? 8 : "50%"}
              style={{
                transform: !label.length ? "translateX(-50%)" : expanded ? "translateX(3.5px)" : "translateX(3.5px)",
              }}
            >
              {active && ActiveIcon ? <ActiveIcon /> : <Icon />}
            </Box>

            <Box
              position="absolute"
              left={48}
              width={expanded ? "100%" : 0}
              style={{ overflow: "hidden", transition: "200ms all" }}
            >
              <Text
                fontSize={14}
                fontWeight={active ? 600 : 400}
                color={activeOnClick && active ? COLORS.blueColor : COLORS.sidebarText}
              >
                {label}
              </Text>
            </Box>
          </NavbarIconWrapper>
        </a>
      )}
    </Tooltip>
  );
};

export type SidebarRef = {
  toggle: () => void;
  setActive: (d: string) => void;
};

const N = <T,>(
  {
    logo,
    onLogoClick = () => {},
    onButtonClick: obc,
    primaryButtons,
    secondaryButtons,
    defaultSelected,
    onExpand,
    defaultExpanded = false,
  }: NavbarProps<T>,
  ref: Ref<SidebarRef>
) => {
  const [active, setActive] = useState<string>(defaultSelected ?? "");

  const [expanded, setExpanded] = useState<boolean | undefined>(defaultExpanded || undefined);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const navBarButtonsRef = useRef<Record<string, HTMLDivElement>>({});

  const dummyBoxRef = useRef<HTMLDivElement>(null);

  const onButtonClick = (btn: NavbarButtonTypes) => {
    setActive(btn.label);
    if (btn.onClick) btn.onClick?.(btn);
    else obc?.(btn);
  };

  const moveDummyBox = (y: number) => {
    if (dummyBoxRef.current) dummyBoxRef.current.style.top = `${y}px`;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onExpand?.(expanded, expanded ? 150 : 56), [expanded]);

  useImperativeHandle(ref, () => ({
    toggle: () => setExpanded((p) => !p),
    setActive: (d: string) => setActive(d),
  }));

  useLayoutEffect(() => {
    moveDummyBox(navBarButtonsRef.current[active]?.getBoundingClientRect().top);
  }, [active, navBarButtonsRef.current]);

  return (
    <Wrapper
      display="inline-flex"
      flexDirection="column"
      alignItems="center"
      backgroundColor={COLORS.sidebarBackground}
      height="100vh"
      paddingTop={16}
      paddingBottom={24}
      justifyContent="space-between"
      position="sticky"
      ref={sidebarRef}
      top={0}
      style={{
        borderRight: "1px solid #dcdfe4",
      }}
      expanded={expanded}
    >
      <Box display="flex" flexDirection="column" alignItems="center" cursor="pointer" onClick={onLogoClick}>
        {logo}
      </Box>

      <Box height={56} width="100%" position="relative">
        <SidebarAction
          expanded={expanded}
          position="absolute"
          right={0}
          top="50%"
          onClick={() => {
            setExpanded((p) => {
              return !p;
            });
          }}
          style={{ transform: "translate(50%, -50%)", cursor: "pointer" }}
        >
          <SidebarActionIcon />
        </SidebarAction>
      </Box>

      <DummyBoxWhichIsMoving ref={dummyBoxRef} />

      <Box display="flex" flexDirection="column" justifyContent="center" width="100%" gap={8}>
        {primaryButtons.map((e) => (
          <Box
            key={e.label}
            ref={(n) => {
              if (n && !navBarButtonsRef.current[e.label]) {
                navBarButtonsRef.current[e.label] = n;
              }
            }}
          >
            <NavbarIcon
              active={active === e.label}
              {...e}
              onClick={() => {
                onButtonClick(e);
              }}
              expanded={expanded}
            />
          </Box>
        ))}
      </Box>

      <Box display="flex" flexDirection="column" width="100%" flex={1} justifyContent="flex-end">
        {secondaryButtons.map((e) => (
          <Box
            key={e.label}
            ref={(n) => {
              if (n) {
                navBarButtonsRef.current[e.label] = n;
              }
            }}
          >
            <NavbarIcon
              active={active === e.label}
              {...e}
              onClick={() => {
                onButtonClick(e);
              }}
              expanded={expanded}
            />
          </Box>
        ))}
      </Box>
    </Wrapper>
  );
};

export const Navbar = forwardRef(N) as <T>(p: NavbarProps<T> & { ref?: Ref<SidebarRef> }) => ReactElement;

import React from "react";

import { Box } from "../box";
import { Divider, DividerProps } from "../divider";
import { Text } from "../text";

export type AppbarProps = {
  heading: string;
  dividerProps?: Partial<DividerProps>;
  items?: {
    position: "right" | "bottom";
    element: JSX.Element;
  };
};

export const Appbar: React.FC<AppbarProps> = ({
  heading,
  items,
  dividerProps = {},
}) => {
  return (
    <Box position="relative">
      <Box display="flex" marginBottom={16} alignItems="center">
        <Text fontSize={18} fontWeight={700} color="#44546F">
          {heading}
        </Text>
        {items?.element ? items.element : null}
      </Box>
      <Divider
        color="rgba(234, 234, 234, .75)"
        height={2}
        {...(dividerProps || {})}
      />
    </Box>
  );
};

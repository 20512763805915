export const SidebarActionIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM13.9238 9.37578C13.8732 9.49655 13.7992 9.60607 13.706 9.69799L11.379 12.005L13.697 14.302C13.7902 14.3939 13.8642 14.5034 13.9148 14.6242C13.9653 14.745 13.9913 14.8746 13.9913 15.0055C13.9913 15.1364 13.9653 15.266 13.9148 15.3868C13.8642 15.5075 13.7902 15.6171 13.697 15.709C13.5079 15.8959 13.2528 16.0007 12.987 16.0007C12.7212 16.0007 12.4661 15.8959 12.277 15.709L9.322 12.779C9.21981 12.6777 9.13869 12.5571 9.08334 12.4242C9.02798 12.2914 8.99948 12.1489 8.99948 12.005C8.99948 11.8611 9.02798 11.7186 9.08334 11.5857C9.13869 11.4529 9.21981 11.3323 9.322 11.231L12.287 8.29099C12.476 8.10443 12.7309 7.99982 12.9965 7.99982C13.2621 7.99982 13.517 8.10443 13.706 8.29099C13.7992 8.38291 13.8732 8.49243 13.9238 8.6132C13.9743 8.73397 14.0003 8.86358 14.0003 8.99449C14.0003 9.1254 13.9743 9.25501 13.9238 9.37578Z"
        fill="#44546F"
      />
    </svg>
  );
};

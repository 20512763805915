import React, { PropsWithChildren, useMemo } from "react";
import { Helmet } from "react-helmet";

import { AppbarProps } from "../appbar";
import { Layout, LayoutProps } from "../layout";

export type PageMetaProps<L = LayoutProps> = React.PropsWithChildren<{
  title: string;
  withLayout?: boolean;
  layoutProps?: L;
  appbarProps?: AppbarProps;
  customLayout?: React.FC<PropsWithChildren<any>>;
}>;

export const PageMeta = <L,>(props: PageMetaProps<L>): React.JSX.Element => {
  const child = useMemo(() => {
    const CustomLayout = props.customLayout;

    if (props.withLayout) {
      return CustomLayout ? (
        <CustomLayout {...(props.layoutProps || {})}>{props.children}</CustomLayout>
      ) : (
        <Layout
          appbarProps={props.appbarProps || ({} as AppbarProps)}
          layoutProps={{
            paddingTop: 16,
            paddingX: 28,
            ...(props.layoutProps || {}),
          }}
        >
          {props.children}
        </Layout>
      );
    } else return props.children as any;
  }, [props]);

  return (
    <React.Fragment>
      <Helmet title={props.title} />
      {child}
    </React.Fragment>
  );
};

enum Environment {
  PROD = "prod",
  DEV = "dev",
  STAGING = "staging",
}

export const rootUrlConfig = {
  [Environment.STAGING]: {
    baseUrl: import.meta.env.VITE_BASE_URL,
    googleKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
    googleMapId: import.meta.env.VITE_GOOGLE_MAPS_ID,
  },
  [Environment.PROD]: {
    baseUrl: import.meta.env.VITE_BASE_URL,
    googleKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
    googleMapId: import.meta.env.VITE_GOOGLE_MAPS_ID,
  },
};

// eslint-disable-next-line no-constant-condition
export const baseUrl =
  import.meta.env.MODE === "staging"
    ? rootUrlConfig[Environment.STAGING].baseUrl
    : rootUrlConfig[Environment.PROD].baseUrl;

export const googleKey =
  import.meta.env.MODE === "staging"
    ? rootUrlConfig[Environment.STAGING].googleKey
    : rootUrlConfig[Environment.PROD].googleKey;

export const googleMapId =
  import.meta.env.MODE === "staging"
    ? rootUrlConfig[Environment.STAGING].googleMapId
    : rootUrlConfig[Environment.PROD].googleMapId;

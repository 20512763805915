import { axiosClient } from "network/core";
import { USER } from "network/endpoints";

export const getColumnPreference = async (d: GetColumnPref) => {
  const res = await axiosClient.get<string[]>({
    url: USER.getColumnPreference,
    query: d,
  });

  res.response = res.response.map((e) => e.trim());

  return res;
};

export const updateColumnPreference = async (d: UpdateColumnPref) => {
  const res = await axiosClient.post<any, undefined>({
    url: USER.updateColumnPreference,
    query: d,
    data: undefined,
  });
  return res;
};

export const getProfileData = async () => {
  const res = await axiosClient.get<Profile>({ url: USER.getProfileData });

  return res;
};

export const updateProfilePicture = async (imageUrl: string) => {
  const res = await axiosClient.put<any, boolean>({
    url: USER.updateProfilePicture,
    query: {
      imageUrl,
    },
    data: undefined,
  });

  return res;
};

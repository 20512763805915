import React from "react";

import { Box } from "../box";

export type DividerProps = {
  orientation?: "vertical" | "horizontal";
  color: string;
  height?: number;
  width?: number;
};

export const Divider: React.FC<DividerProps> = ({
  orientation = "horizontal",
  color,
  height = 1,
  width = 1,
}) => {
  if (orientation === "horizontal") {
    return <Box width="100%" height={height} backgroundColor={color} />;
  } else {
    return <Box width={width} height={height} backgroundColor={color} />;
  }
};

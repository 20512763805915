import { doCheckToken, doLogin, doLogout } from "network/services/auth";
import { getProfileData } from "network/services/user";

import { StaticKeys } from "types/enum";

import { useFetch, useMutate } from "hooks";

import { giveMinuteInSeconds } from "helpers/time";

export const useLogin = () => {
  return useMutate<LoginResponse, LoginPostBody>({
    fn: doLogin,
  });
};

export const useCheckToken = () => {
  return useFetch({
    key: ["check-token"],
    queryFn: doCheckToken,
    refetchInterval: giveMinuteInSeconds(15),
  });
};

export const useProfileData = ({ isLoggedIn = false }: { isLoggedIn: boolean }) => {
  return useFetch({
    key: ["profile"],
    queryFn: getProfileData,
    refetchInterval: giveMinuteInSeconds(30),
    staleTime: Infinity,
    enabled: isLoggedIn,
    onSuccess: (data) => {
      if (data.isSuccessful) localStorage.setItem("fc", data.response[StaticKeys.FLEET_ID].toString());
    },
  });
};

export const useLogout = () => {
  return useMutate({
    fn: doLogout,
  });
};

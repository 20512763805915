/* eslint-disable react/display-name */
import React, { forwardRef, PropsWithChildren } from "react";
import styled from "styled-components";

type TextType = "p" | "span";

export type TextProps<T extends TextType> = {
  fontSize?: number;
  fontWeight?: number;
  color?: string;
  lineHeight?: number;
  textType?: TextType;
} & Omit<React.JSX.IntrinsicElements[T], "ref">;

const StyledText = styled.p<TextProps<"p">>`
  font-size: ${(props) => {
    return props.fontSize;
  }}px;
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight}px;
  color: ${(props) => props.color};

  margin: 0 !important;
`;

export const Text = forwardRef<
  HTMLParagraphElement | HTMLSpanElement,
  PropsWithChildren<TextProps<"p">>
>(({ children, ...props }, ref) => (
  <StyledText ref={ref} as={props.textType} {...props}>
    {children}
  </StyledText>
));

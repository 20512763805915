import React from "react";

import { Appbar, AppbarProps } from "../appbar";
import { Box, BoxPropTypes } from "../box";

export type LayoutProps = React.PropsWithChildren<{
  layoutProps: Omit<BoxPropTypes, "ref" | "children">;
  appbarProps: AppbarProps;
}>;

export const Layout: React.FC<LayoutProps> = ({
  children,
  layoutProps,
  appbarProps,
}) => {
  return (
    <Box {...layoutProps}>
      <Appbar {...appbarProps} />
      {children}
    </Box>
  );
};

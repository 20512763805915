import { useNavigate } from "react-router-dom";
import { Box, Button } from "blucharge-react-core";

import { PageMeta } from "components/page-meta";
import { Text } from "components/text";

import { BackIcon } from "assets/icon/back";

import { ROUTES } from "routes";

import notFound from "../assets/images/not_found.png";
export const Page404 = () => {
  const navigate = useNavigate();

  return (
    <PageMeta withLayout={false} title="Page not found">
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <Box display="flex" flexDirection="column" gap={48} alignItems="center">
          <Box display="flex" flexDirection="column" gap={60} alignItems="center">
            <img width={96} height={96} src={notFound} alt="not_found" />
            <Box display="flex" flexDirection="column" gap={36} alignItems="center">
              <Text fontSize={48} color="#0C66E4" fontWeight={600}>
                Error 404
              </Text>
              <Text fontSize={18} color="#0F0F0F" fontWeight={400}>
                Oops! The page you’re trying to access can not be found.
              </Text>
            </Box>
          </Box>

          <Box display="flex" gap={20}>
            <Button
              onClick={() => navigate(-1)}
              style={{
                width: 170,
                height: 60,
                display: "flex",
                alignItems: "center",
                fontSize: 16,
                color: "#898989",
              }}
              leftAdornment={<BackIcon />}
            >
              Go Back
            </Button>
            <Button
              onClick={() => navigate(ROUTES.stations)}
              style={{
                width: 170,
                height: 60,
                display: "flex",
                alignItems: "center",
                fontSize: 16,
              }}
              variant="primary"
            >
              Home
            </Button>
          </Box>
        </Box>
      </Box>
    </PageMeta>
  );
};

import React, { AllHTMLAttributes, forwardRef } from "react";
import T, { TextFieldProps } from "@atlaskit/textfield";
import styled from "styled-components";

type DefaultHtmlAttributes = keyof AllHTMLAttributes<HTMLInputElement>;

type CustomVariants = "light" | "contained" | "outlined";

export type InputProps = Omit<
  Omit<TextFieldProps, DefaultHtmlAttributes>,
  "appearance" | "elemAfterInput" | "elemBeforeInput" | "onChange"
> & {
  variant?: TextFieldProps["appearance"] | CustomVariants;
  rightAdornment?: TextFieldProps["elemAfterInput"];
  leftAdornment?: TextFieldProps["elemBeforeInput"];
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextField: any = styled(T)`
  display: flex;
  gap: 4px;
  padding: 6px 10px;
  max-height: 32px;
  min-height: 32px;

  border-radius: 5px !important;
`;

const Outlined = styled(TextField)`
  input {
    padding: 0px !important;
    height: unset !important;

    font-size: 14px !important;
    font-weight: 500;
    color: #172b4d;

    &::-webkit-input-placeholder {
      color: #626f86;
      font-size: 14px !important;
      font-weight: 400;
    }
  }

  svg path {
    fill: #626f86;
    font-size: 14px !important;
    font-weight: 500;
  }
`;

const Contained = styled(TextField)`
  border-radius: 3px !important;
  border: none !important;
  background-color: #091e420f !important;

  input {
    padding: 0px !important;
    height: unset !important;

    font-size: 14px !important;
    font-weight: 500;
    color: #172b4d;

    &::-webkit-input-placeholder {
      color: #6e7b90;
      font-size: 14px !important;
      font-weight: 400;
    }
  }
`;

// eslint-disable-next-line react/display-name
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const Component =
    props.variant === "outlined"
      ? Outlined
      : props.variant === "contained"
      ? Contained
      : TextField;

  return (
    <Component
      {...props}
      ref={ref}
      elemBeforeInput={props.leftAdornment}
      elemAfterInput={props.rightAdornment}
    />
  );
});
